<template>
  <div class="">
    <router-view></router-view>
    <contact-info-inviter></contact-info-inviter>
  </div>
</template>

<script>
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';

export default {
  name: 'note-viewer',
  components: { ContactInfoInviter }
}
</script>
